import axios from "axios";
import { OrderDerivative, OrderUnderlyingAsset } from "../@types/order";

export const orders = {
  getOrdersUnderlyingAsset: () => {
    return axios.get<OrderUnderlyingAsset[]>(
      `${process.env.REACT_APP_BASE_URL}/api/orders/underlyingAsset`,
    );
  },
  createOrderUnderlyingAsset: (order: OrderUnderlyingAsset) => {
    return axios.post<OrderUnderlyingAsset>(
      `${process.env.REACT_APP_BASE_URL}/api/orders/underlyingAsset`,
      order
    )
    .then();
  },
  createOrderDerivative: (order: OrderDerivative) => {
    return axios.post<OrderDerivative>(
      `${process.env.REACT_APP_BASE_URL}/api/orders/derivative`,
      order
    );
  },
  updateOrderUnderlyingAsset: (order: OrderUnderlyingAsset) => {
    return axios.put<OrderUnderlyingAsset>(
      `${process.env.REACT_APP_BASE_URL}/api/orders/underlyingAsset`,
      order
    );
  },
  cancelOrderUnderlyingAsset: (orderId: string | undefined) => {
    if (orderId !== undefined) {
      return axios.post<OrderUnderlyingAsset>(
        `${process.env.REACT_APP_BASE_URL}/api/orders/underlyingAsset/cancel/${orderId}`
      );
    }
  }
}

export default orders