import { createContext, useContext, useState } from 'react';
import CustomSnackbar from './CustomSnackbar';

const SnackbarContext = createContext(null);

export const SnackbarProvider = ({ children }: any) => {
  const [snackbars, setSnackbars] = useState<any>([]);
  let snackbarId = 0;

  const showSnackbar = (message: string, severity: string) => {
    const id = snackbarId++;
    setSnackbars((prevSnackbars: any) => [
      ...prevSnackbars,
      { id, message, severity }
    ]);

    // Automatically remove the Snackbar after 6 seconds
    setTimeout(() => {
      setSnackbars((prevSnackbars: any) =>
        prevSnackbars.filter((snackbar: any) => snackbar.id !== id)
      );
    }, 6000);
  };

  const handleCloseSnackbar = (id: number) => {
    setSnackbars((prevSnackbars: any) =>
      prevSnackbars.filter((snackbar: any) => snackbar.id !== id)
    );
  };

  return (
    // @ts-expect-error
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      {snackbars.map((snackbar: any) => (
        <CustomSnackbar
          key={snackbar.id}
          open
          onClose={() => handleCloseSnackbar(snackbar.id)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      ))}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);