import axios from "axios";

export const positions = {
  getPositions: () => {
    return axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/positions`
    );
  }
}

export default positions