import { Box, Collapse, Container, IconButton, Stack, Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Fragment } from 'react/jsx-runtime'
import { useEffect, useState } from "react"
import { OrderUnderlyingAsset } from "../@types/order"
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import BracketOrderDialog, { BracketOrderFormData } from "../components/BracketOrderDialog";
import ordersApi from "../api/orders"
import ConfirmDialog from "../components/ConfirmDialog";
import { useSnackbar } from "../components/snackbar/SnackbarContext";
import _ from "lodash";

interface RowProps {
  row: OrderUnderlyingAsset
  refreshOrders: () => void
}

function Row(props: RowProps) {
  const { row, refreshOrders } = props;
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  // @ts-expect-error
  const { showSnackbar } = useSnackbar();

  const handleDialogClose = () => {
    setDialogOpen(false)
  };

  const handleEditOrder = (order: OrderUnderlyingAsset, data: BracketOrderFormData) => {
    order.target = parseFloat(data.target)
    order.stopLoss = parseFloat(data.stopLoss)
    ordersApi.updateOrderUnderlyingAsset(order)
      .then(() => showSnackbar('Order updated', 'success'))
      .catch(() => showSnackbar('Error updating order', 'error'))
  }

  const handleCancelOrder = () => {
    ordersApi.cancelOrderUnderlyingAsset(row.orderId)
      ?.then(() => showSnackbar('Order canceled', 'success'))
        .catch(() => showSnackbar('Error canceling order', 'error'))
        .finally(refreshOrders)
    setConfirmDialogOpen(false)
  }

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{row.instrumentToken}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell align="right">{row.target}</TableCell>
        <TableCell align="right">{row.stopLoss}</TableCell>
        <TableCell align="right">{row.status}</TableCell>
        {row.status === "CREATED" &&
          <TableCell align="right">
            <IconButton color="warning" aria-label="edit" onClick={() => setDialogOpen(true)}>
              <EditIcon />
            </IconButton>
            <IconButton color="secondary" aria-label="cancel" onClick={() => setConfirmDialogOpen(true)}>
              <CancelIcon />
            </IconButton>
            <BracketOrderDialog
              open={dialogOpen}
              formData={{ target: (row.target || '').toString(), stopLoss: (row.stopLoss || '').toString() }}
              handleClose={handleDialogClose}
              handleSubmit={(formData) => handleEditOrder(row, formData)}
            />
            <ConfirmDialog
              open={confirmDialogOpen}
              title="Confirm Cancel"
              content="Are you sure you want to cancel?"
              onClose={() => setConfirmDialogOpen(false)}
              onConfirm={handleCancelOrder}
            />
          </TableCell>}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Derivative
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Trading Symbol</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>Exchange</TableCell>
                    <TableCell>Transaction Type</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.derivativeOrders.map((derivativeOrder) => (
                    <TableRow
                      key={derivativeOrder.exchange + ":" + derivativeOrder.tradingSymbol}
                      sx={{
                        '&:last-child td': {
                          borderBottom: 0
                        }
                      }}>
                      <TableCell>{derivativeOrder.tradingSymbol}</TableCell>
                      <TableCell>{derivativeOrder.product}</TableCell>
                      <TableCell>{derivativeOrder.exchange}</TableCell>
                      <TableCell>{derivativeOrder.transactionType}</TableCell>
                      <TableCell align="right">{derivativeOrder.quantity}</TableCell>
                      <TableCell align="right">{derivativeOrder.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

interface OrderTableProps {
  orderStatus?: string
  orders: OrderUnderlyingAsset[]
  refreshOrders: () => void
}

function OrderTable(props: OrderTableProps) {

  const { orderStatus, orders, refreshOrders } = props

  return (
    <TableContainer sx={{ p: 2 }} component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>
              <Typography fontWeight="bold">Instrument Token</Typography>
            </TableCell>
            <TableCell>
              <Typography fontWeight="bold">Name</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography fontWeight="bold">Target</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography fontWeight="bold">Stop Loss</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography fontWeight="bold">Status</Typography>
            </TableCell>
            {orderStatus === "CREATED" &&
              <TableCell align="right">
                <Typography fontWeight="bold">Action</Typography>
              </TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            orders.map((row) => (
              <Row key={row.orderId} row={row} refreshOrders={refreshOrders} />
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function OrdersPage() {

  const [createdOrders, setCreatedOrders] = useState<OrderUnderlyingAsset[]>([])
  const [otherOrders, setOtherOrders] = useState<OrderUnderlyingAsset[]>([])

  useEffect(() => {
    refreshOrdersUnderlyingAsset()
  }, [])

  const refreshOrdersUnderlyingAsset = () => {
    ordersApi.getOrdersUnderlyingAsset()
      .then(res => res.data)
      .then((data) => _.partition(data, o => o.status === "CREATED"))
      .then(([created, other]) => {
        setCreatedOrders(created)
        setOtherOrders(other)
      })
  }

  return (
    <Container>
      <Stack spacing={2}>
        {createdOrders.length !== 0 && <OrderTable orderStatus="CREATED" orders={createdOrders} refreshOrders={refreshOrdersUnderlyingAsset} />}
        {otherOrders.length !== 0 && <OrderTable orders={otherOrders} refreshOrders={refreshOrdersUnderlyingAsset} />}
      </Stack>
    </Container>
  )
}

export default OrdersPage