import { Route, Routes as ReactRoutes, Navigate } from "react-router-dom";
import PositionsPage from "./pages/PositionsPage";
import OrdersPage from "./pages/OrdersPage";

const Routes = () => {
  return (
    <ReactRoutes>
      <Route path="/" element={<Navigate to="/positions" replace />} />
      <Route path="/positions" element={<PositionsPage />} />
      <Route path="/orders" element={<OrdersPage />} />
    </ReactRoutes>
  );
};

export default Routes;
