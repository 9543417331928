import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { Position } from '../@types/position';
import { Button, Grid, IconButton, Stack } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { useState } from 'react';
import { getDerivativeCompleteName } from '../utils/helper';
import { OrderDerivative } from '../@types/order';
import BracketOrderDialog, { BracketOrderFormData } from './BracketOrderDialog';
import ordersApi from '../api/orders'
import { useSnackbar } from './snackbar/SnackbarContext';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Position;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'product',
    numeric: false,
    disablePadding: false,
    label: 'Product',
  },
  {
    id: 'exchange',
    numeric: false,
    disablePadding: false,
    label: 'Exchange',
  },
  {
    id: 'tradingSymbol',
    numeric: false,
    disablePadding: false,
    label: 'Instrument',
  },
  {
    id: 'netQuantity',
    numeric: true,
    disablePadding: false,
    label: 'Qty',
  }
];

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all positions',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            <Typography fontWeight="bold">
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  name: string;
  numSelected: number;
  handleCreateOrder: (data: BracketOrderFormData) => void
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { name, numSelected, handleCreateOrder } = props;

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleBracketOrderClick = () => {
    setDialogOpen(true);
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      <Grid container justifyContent="space-between">
        <Grid item>
          {numSelected > 0 ? (
            <Typography
              sx={{ flex: '1 1 100%' }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} selected
            </Typography>
          ) : (
            <Typography
              sx={{ flex: '1 1 100%' }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {name}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Stack spacing={1} direction="row">
            <IconButton>
              <Refresh color="primary" aria-label="refresh" />
            </IconButton>
            <Button disabled={true} variant='contained' sx={{ whiteSpace: 'nowrap' }}>
              <Typography variant='button'>
                Square Off
              </Typography>
            </Button>
            <Button disabled={numSelected <= 0} variant='contained' sx={{ whiteSpace: 'nowrap' }}
              onClick={handleBracketOrderClick}>
              <Typography variant='button'>
                Place Bracket Order
              </Typography>
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <BracketOrderDialog
        open={dialogOpen}
        formData={{ target: '', stopLoss: '' }}
        handleClose={handleDialogClose}
        handleSubmit={handleCreateOrder}
      />
    </Toolbar>
  );
}

interface PositionsTableProps {
  name: string
  positions: Position[]
}

export default function PositionsTable(props: PositionsTableProps) {
  const { name, positions } = props
  const [selected, setSelected] = useState<readonly string[]>([]);
  // @ts-expect-error
  const { showSnackbar } = useSnackbar();

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = positions.filter(p => p.netQuantity !== 0).map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    const p = positions.find(position => position.id === id)
    if (p?.netQuantity === 0) {
      return;
    }

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleCreateOrder = (data: BracketOrderFormData) => {
    const orderDerivatives = positions.filter(position => selected.includes(position.id))
      .map<OrderDerivative>(position => ({
        product: position.product,
        exchange: position.exchange,
        tradingSymbol: position.tradingSymbol,
        transactionType: position.netQuantity < 0 ? "BUY" : "SELL",
        quantity: Math.abs(position.netQuantity),
        name: position.underlyingAsset.name
      }))
    // @ts-expect-error
    const orderUnderlyingAsset: OrderUnderlyingAsset = {
      instrumentToken: positions[0].underlyingAsset.instrumentToken,
      name: positions[0].underlyingAsset.name
    }
    if (data.target !== "") {
      orderUnderlyingAsset.target = parseFloat(data.target)
    }
    if (data.stopLoss !== "") {
      orderUnderlyingAsset.stopLoss = parseFloat(data.stopLoss)
    }
    orderUnderlyingAsset.derivativeOrders = orderDerivatives
    ordersApi.createOrderUnderlyingAsset(orderUnderlyingAsset)
    .then(() => showSnackbar('Order placed', 'success'))
  }

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, p: 3 }}>
        <EnhancedTableToolbar name={name} numSelected={selected.length} handleCreateOrder={handleCreateOrder} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size='small'
          >
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={positions.filter(p => p.netQuantity !== 0).length}
            />
            <TableBody>
              {positions.map((position, index) => {
                const isItemSelected = isSelected(position.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, position.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={position.id}
                    selected={isItemSelected}
                    sx={{
                      cursor: position.netQuantity === 0 ? 'auto' : 'pointer',
                      '&:last-child td': {
                        borderBottom: 0
                      }
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        disabled={position.netQuantity === 0}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                        sx={{
                          display: position.netQuantity === 0 ? 'none' : null
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Typography component="div">
                        {position.product}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography component="div">
                        {position.exchange}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography component="div">
                        {getDerivativeCompleteName(position)}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography component="div">
                        {position.netQuantity}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
