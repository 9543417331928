import { BrowserRouter as Router } from "react-router-dom";
import { useEffect, useState } from 'react';
import Layout from './Layout';
import { getCurrentUser } from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'
import SignInPage from './pages/SignInPage';
import { Amplify } from 'aws-amplify'
import { amplifyConfig } from './amplify/config';
import { signOut } from "@aws-amplify/auth";
import './App.css';
import './axios'
import { SnackbarProvider } from "./components/snackbar/SnackbarContext";

Amplify.configure(amplifyConfig)

function App() {

  const [currentUser, setCurrentUser] = useState<any>(true)

  useEffect(() => {
    getCurrentUser()
      .then((data: any) => {
        setCurrentUser(data.username)
      })
      .catch((error: any) => {
        setCurrentUser(null)
      })
    Hub.listen('auth', (event) => {
      if (event.payload.event === 'signedIn') {
        setCurrentUser(event.payload.data.username)
      } else if (event.payload.event === 'signedOut') {
        setCurrentUser(null)
      }
    })
  })

  const onLogout = () => {
    signOut().then(() => {
      setCurrentUser(null)
    });
  }

  return (
    <Router>
      <SnackbarProvider>
        {currentUser ? <Layout onLogout={onLogout} /> : <SignInPage />}
      </SnackbarProvider>
    </Router>
  );
}

export default App;
