import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { useState } from "react";

export interface BracketOrderDialogProps {
  open: boolean;
  formData: BracketOrderFormData;
  handleClose: () => void;
  handleSubmit: (formData: BracketOrderFormData) => void;
}

export interface BracketOrderFormData {
  target: string;
  stopLoss: string;
}

function BracketOrderDialog(props: BracketOrderDialogProps) {
  const { open, formData: initialData, handleClose, handleSubmit: submit } = props
  const [formData, setFormData] = useState(initialData);
  const [errors, setErrors] = useState({ target: false, stopLoss: false });
  const [helperTexts, setHelperTexts] = useState({ target: '', stopLoss: '' });

  // Validate the form
  const validateForm = () => {
    let valid = true;
    const newErrors = { target: false, stopLoss: false };
    const newHelperTexts = { target: '', stopLoss: '' };

    if (formData.target === "" && formData.stopLoss === "") {
      valid = false;
      newErrors.target = true;
      newHelperTexts.target = 'Atlease one field must be given';
    } else {
      if (formData.target !== "" && isNaN(parseFloat(formData.target))) {
        valid = false;
        newErrors.target = true;
        newHelperTexts.target = 'Target is a number';
      }

      if (formData.stopLoss !== "" && isNaN(parseFloat(formData.stopLoss))) {
        valid = false;
        newErrors.stopLoss = true;
        newHelperTexts.stopLoss = 'Stop Loss is a number';
      }
    }

    setErrors(newErrors);
    setHelperTexts(newHelperTexts);

    return valid;
  };

  // Handle input changes
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    // Clear errors and helper texts when user starts typing
    setErrors({ ...errors, [name]: false });
    setHelperTexts({ ...helperTexts, [name]: '' });
  };

  // Handle form submission
  const handleSubmit = () => {
    if (validateForm()) {
      submit(formData)
      handleClose();
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Brack Order</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Target"
            type="text"
            fullWidth
            name="target"
            value={formData.target}
            onChange={handleChange}
            error={errors.target}
            helperText={helperTexts.target}
          />
          <TextField
            margin="dense"
            label="Stop Loss"
            type="text"
            fullWidth
            name="stopLoss"
            value={formData.stopLoss}
            onChange={handleChange}
            error={errors.stopLoss}
            helperText={helperTexts.stopLoss}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Typography>
              Cancel
            </Typography>
          </Button>
          <Button onClick={handleSubmit} color="primary">
            <Typography>
              Submit
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default BracketOrderDialog