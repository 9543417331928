import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import { useState } from "react";
import { getDerivativeCompleteName } from "../utils/helper";
import { OrderDerivative } from "../@types/order";
import { useSnackbar } from "./snackbar/SnackbarContext";
import orderApi from "../api/orders"

interface ActiveStrikeProps {
  bgColor: string;
  strikeDetails: any
}

const ActiveStrike = (props: ActiveStrikeProps) => {
  const { bgColor, strikeDetails } = props
  const [quantity, setQuantity] = useState(0);
  // @ts-expect-error
  const { showSnackbar } = useSnackbar();

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault(); // Prevent default behavior
    setQuantity(Number(event.target.value));
  };

  const handleKeyDown = (event: any) => {
    event.preventDefault(); // Prevent default behavior
    if (event.key === "ArrowUp") {
      setQuantity((prev) => prev + (strikeDetails?.lotSize || 0));
    } else if (event.key === "ArrowDown") {
      setQuantity((prev) => prev - (strikeDetails?.lotSize || 0));
    }
  };

  const handleBuyOrSell = (transactionType: string) => {
    const order: OrderDerivative = {
      product: "NRML",
      exchange: strikeDetails.exchange,
      tradingSymbol: strikeDetails.tradingSymbol,
      transactionType: transactionType,
      quantity: quantity,
      name: strikeDetails.name
    }
    orderApi.createOrderDerivative(order)
      .then(res => res.data)
      .then(data => {
        if (data.error != null) {
          showSnackbar(data.error, 'error')
        } else {
          showSnackbar('Order placed', 'success')
        }
      })
      .catch(alert)
  };

  return (
    <div>
      <Card sx={{ p: 1, backgroundColor: bgColor }}>
        <CardContent>
          <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
            {getDerivativeCompleteName(strikeDetails || {})}
          </Typography>
          <Stack spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography color={green[500]}>
                {new Intl.NumberFormat("en-IN").format(
                  strikeDetails?.totalBuyQuantity || 0
                )}
              </Typography>
              <Typography color={red[500]}>
                {new Intl.NumberFormat("en-IN").format(
                  strikeDetails?.totalSellQuantity || 0
                )}
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>BSR</Typography>
                <Chip
                  label={(strikeDetails?.buyToSellRatio || 0).toFixed(2)}
                  color="warning"
                />
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <Typography>Lot size</Typography>
                <Typography>
                  {strikeDetails?.lotSize || 0}
                </Typography>
              </Stack>
              <Stack>
                <Typography>Amount required</Typography>
                <Typography alignSelf="end">
                  {new Intl.NumberFormat("en-IN").format(
                    quantity * strikeDetails?.lastTradedPrice || 0
                  )}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
        <Divider />
        <CardActions>
          <Stack
            width="100%"
            ml={1.5}
            direction="row"
            justifyContent="space-between"
          >
            <TextField
              label="Quantity"
              type="number"
              InputLabelProps={{
                shrink: true
              }}
              variant="standard"
              value={quantity}
              onChange={handleQuantityChange}
              onKeyDown={handleKeyDown}
            />
            <Stack flex={0.5} direction="row">
              <Button
                onClick={() => handleBuyOrSell("BUY")}
                size="large"
                sx={{ color: green[500] }}
              >
                Buy
              </Button>
              <Button onClick={() => handleBuyOrSell("SELL")}
                size="large"
                sx={{ color: red[500] }}
              >
                Sell
              </Button>
            </Stack>
          </Stack>
        </CardActions>
      </Card>
    </div>
  );
};

export default ActiveStrike;
