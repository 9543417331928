import { Snackbar, Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface CustomSnackbarProps {
  open: boolean
  severity: any
  message: string
  onClose: () => void
}

const CustomSnackbar = (props: CustomSnackbarProps) => {

  const { open, severity = 'info', message, onClose } = props

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000} // Automatically hide after 6 seconds
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position at top-right
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    >
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;