import { ResourcesConfig } from 'aws-amplify'

export const amplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolClientId: `${process.env.REACT_APP_COGNITO_USERPOOL_CLIENT_ID}`,
      userPoolId: `${process.env.REACT_APP_COGNITO_USERPOOL_ID}`,
      loginWith: {
        username: true
      }
    }
  }
}