import axios from "axios";

export const options = {
  getActiveStrikes: (indexName: string, numberOnEachSide: number) => {
    return axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/options/activeStrikes/${indexName}?numberOnEachSide=${numberOnEachSide}`
    );
  }
}

export default options