import axios from "axios";

export const user = {
  getZerodhaLoginURL: () => {
    return axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/user/login/zerodha/url`
    );
  },
  isConnectedToZerodha: () => {
    return axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/user/zerodha/isConnected`
    );
  },
  getZerodhaProfile: () => {
    return axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/user/login/zerodha/profile`
    );
  }
}

export default user