import moment from "moment";
import { Position } from "../@types/position";

export const getDerivativeCompleteName = ({
  name,
  strike,
  instrumentType,
  expiry
}: Position) => {
  const date = moment(expiry);
  const formattedDate = date.format("DD MMM");
  return name + " " + formattedDate + " " + strike + " " + instrumentType;
};
