import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import Routes from "./Routes";
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoginIcon from '@mui/icons-material/Login';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import user from './api/user';

const drawerWidth = 240;

const routes = [
  { name: 'Positions', link: '/positions' },
  { name: 'Orders', link: '/orders' }
]

const CollapsibleListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(0)
}));

interface CollapsibleListProps {
  text: string
  children: any
}

function CollapsibleList(props: CollapsibleListProps) {
  const { text, children } = props

  const [open, setOpen] = useState(true);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  return (
    <List>
      <ListItem sx={{
        pl: 1,
        pb: 0,
        mb: 0,
        '&:hover': {
          cursor: 'pointer',
          userSelect: 'none'
        },
      }} onClick={handleToggle}>
        <ListItemIcon>
          <IconButton>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </ListItemIcon>
        <ListItemText
          primary={<Typography>{text}</Typography>}
        />
      </ListItem>
      {open && children}
    </List>
  );
};

interface LayoutProps {
  onLogout: () => void
}

function Layout(props: LayoutProps) {

  const { onLogout } = props

  const [isZerodhaConnected, setZerodhaConnected] = useState(false)
  const [zerodhaProfile, setZerodhaProfile] = useState(null)

  const navigate = useNavigate()
  const location = useLocation();

  useEffect(() => {
    user.isConnectedToZerodha()
      .then(res => res.data?.isConnected)
      .then(setZerodhaConnected)
    user.getZerodhaProfile()
      .then(res => res.data?.username)
      .then(setZerodhaProfile)
  }, [])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Fling Trade
          </Typography>
          <IconButton onClick={onLogout} color="inherit" aria-label="logout">
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {routes.map((route, index) => (
              <ListItem key={route.name} disablePadding>
                <ListItemButton onClick={() => navigate(route.link)}>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={route.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {['All mail', 'Trash', 'Spam'].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <CollapsibleList text='Zerodha'>
            <CollapsibleListItem>
              <ListItemButton>
                <ListItemIcon>
                  {isZerodhaConnected ? <CloudQueueIcon sx={{color: 'green'}} /> : <CloudOffIcon sx={{color: 'red'}} />}
                </ListItemIcon>
                <ListItemText primary="Websocket" />
              </ListItemButton>
            </CollapsibleListItem>
            <CollapsibleListItem>
              <ListItemButton onClick={() =>
                !zerodhaProfile && user.getZerodhaLoginURL()
                  .then(res => res.data)
                  .then(url => {
                    const { pathname, search, hash } = location;
                    const currentUrl = `${window.location.origin}${pathname}${search}${hash}`;
                    window.location.href = `${url}&redirect_params=redirect_url%3D${encodeURI(currentUrl)}`
                  })}>
                <ListItemIcon>
                  {zerodhaProfile ? <AccountCircleIcon /> : <LoginIcon />}
                </ListItemIcon>
                <ListItemText primary={zerodhaProfile || 'Login'} />
              </ListItemButton>
            </CollapsibleListItem>
          </CollapsibleList>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: '#F5F5F5', p: 3, height: '100vh' }}
      >
        <Toolbar />
        <Routes />
      </Box>
    </Box>
  )
}

export default Layout