import { Box, Button, Container, Divider, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import PositionsTable from '../components/PositionsTable';
import { useEffect, useState } from 'react';
import ActiveStrike from '../components/ActiveStrike';
import positionsApi from "../api/positions"
import optionsApi from "../api/options"
import { useSnackbar } from '../components/snackbar/SnackbarContext';
import _ from "lodash"

const NUMBER_ON_EACH_SIDE = 3
const INDEXES = ["NIFTY", "BANKNIFTY", "FINNIFTY", "MIDCPNIFTY", "SENSEX"]

function PositionsPage() {

  const [callActiveStrike, setCallActiveStrike] = useState({});
  const [putActiveStrike, setPutActiveStrike] = useState({});
  const [nextExpiryActiveStrikes, setNextExpiryActiveStrikes] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState("NIFTY");
  const [groupedPositions, setGroupedPositions] = useState({})

  // @ts-expect-error
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    getActiveStrikes(selectedIndex, NUMBER_ON_EACH_SIDE)
    getPositions()
  }, [selectedIndex])

  const handleIndexChange = (e: any) => {
    e.preventDefault();
    const i = e.target.value
    setSelectedIndex(i);
    getActiveStrikes(i, NUMBER_ON_EACH_SIDE)
  };

  const getPositions = () => {
    positionsApi.getPositions()
      .then(res => res.data)
      .then(data => _.groupBy(data, 'underlyingAsset.name'))
      .then(setGroupedPositions)
  }

  const getActiveStrikes = (indexName: string, numberOnEachSide: number) => {
    optionsApi.getActiveStrikes(indexName, numberOnEachSide)
      .then(res => res.data)
      .then(data => {
        setCallActiveStrike(data.callActiveStrike);
        setPutActiveStrike(data.putActiveStrike);
        setNextExpiryActiveStrikes(data.nextExpiry)
      }).catch(error => {
        showSnackbar(error.response.data.message, 'error')
      })
  };

  return (
    <Container>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Box flex={1}>
            <ActiveStrike bgColor="#EFFBFA" strikeDetails={callActiveStrike} />
          </Box>
          <Box flex={1}>
            <ActiveStrike bgColor="#FDF3F4" strikeDetails={putActiveStrike} />
          </Box>
          <Box flex={0.7}>
            <Stack spacing={2}>
              <FormControl size="medium">
                <InputLabel shrink={true} id="index-select-label">
                  Index
                </InputLabel>
                <Select
                  labelId="index-select-label"
                  id="index-select"
                  label="Index"
                  value={selectedIndex}
                  onChange={handleIndexChange}
                >
                  {INDEXES.map((index) => (
                    <MenuItem key={index} value={index}>
                      {index}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button size='large' variant='outlined' onClick={() => getActiveStrikes(selectedIndex, NUMBER_ON_EACH_SIDE)}>
                <Typography>
                  Refresh
                </Typography>
              </Button>
            </Stack>
          </Box>
        </Stack>
        {nextExpiryActiveStrikes && <Stack direction="row" spacing={2} justifyContent="space-between">
          <Box flex={1}>
            <ActiveStrike bgColor="#EFFBFA" strikeDetails={nextExpiryActiveStrikes.callActiveStrike} />
          </Box>
          <Box flex={1}>
            <ActiveStrike bgColor="#FDF3F4" strikeDetails={nextExpiryActiveStrikes.putActiveStrike} />
          </Box>
          <Box flex={0.7}>
          </Box>
        </Stack>}
        {Object.entries(groupedPositions).map(([name, positions], index) => {
          return INDEXES.includes(name) ? <PositionsTable
            key={index}
            name={name}
            // @ts-expect-error
            positions={_.orderBy(positions, (o) => Math.abs(o.netQuantity), 'desc')}
          /> : null
        })}
      </Stack>
    </Container>
  )
}

export default PositionsPage